if(top.location != self.location){ // Break out of frames
	top.location = self.location.href
}

// PWA
import PWA from "./includes/service-worker/pwa"
PWA.init()

window.enabledCountries = ""

import "./includes/dayjs.js"

// Setup Swiper Element
import { register } from 'swiper/element/bundle'
register()

// Import Sentry
import * as Sentry from '@sentry/vue'

// Important for security. Strip "data" parameter from URL if it's not terms-and-conditions page where it is expected
if(location.search.indexOf('data') === 1 && !location.pathname.indexOf("terms-and-conditions")){
	window.history.replaceState(false, '', location.pathname)
}

// Loading lodash selected functions (reducing size)
import {
	isEmpty,
	forEach,
	capitalize,
	toPairs,
	shuffle,
	map,
	keys,
	size,
	includes,
	concat,
} from 'lodash'

window._ = {
	isEmpty,
	forEach,
	capitalize,
	toPairs,
	shuffle,
	map,
	keys,
	size,
	includes,
	concat,
}

// Import SCSS
import '@/scss/loader.scss'
import '@/scss/app.scss'

// Register global Events bus handler
import Events from "./includes/event-bus.js"
window.Events = Events

// Import axios
import axios from "./includes/axios.js"


// Setup router 
import { router } from "./routes.js"
import { createApp } from 'vue/dist/vue.esm-bundler.js'

// Import the Vuex store
import { store } from './vuex/store.js'

import { VueToastr } from "vue-toastr"
import VueLazyload from 'vue3-lazyload'
import VueScrollTo from 'vue-scrollto'

import Loader from './components/loader.vue'
import Checkbox from './components/checkbox.vue'
import Radio from './components/radio.vue'

import { Modal } from '@kouts/vue-modal'
import '@kouts/vue-modal/dist/vue-modal.css'
import transitionHeight from "./components/blocks/transition-height.vue"
import ResponsiveImage from './components/blocks/responsive-image.vue'

import "./includes/font-awesome.js"
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

// Import Vue app
import App from "./components/app.vue"

// Request settings 
axios.get('/api/user-side/getSettings', {
	params: {
		url: document.location.pathname.substring(1),
	}
})
.then(response => {
	let settings = response.data

	store.commit("setSettings", settings)

	if(settings.authenticated == false){
		store.commit('setAuthenticated', false)
	}

	// Create the app
	const app = createApp(App)

	// Enable error tracking
	if(settings.sentryUrl){
		Sentry.init({
			app,
			dsn: settings.sentryUrl,
			integrations: [
				Sentry.browserTracingIntegration({ router }),
				Sentry.replayIntegration(),
			],
			release: "challenger@" + __deploymentVersion,
			allowUrls: [ window.location.origin ], // Silent errors happening in the external code
		})
	}

	// Register plugins
	app.use(store)
	app.use(router)

	// Register VueToastr
	app.use(VueToastr, {
		defaultPosition: "toast-bottom-right",
	})

	// Load lazyloader for challenge images
	app.use(VueLazyload, {
		preLoad: 1.3,
		error: '/customer/images/image-spinner.gif',
		loading: '/customer/images/image-spinner.gif',
		attempt: 1
	})

	// Add scroll to
	app.use(VueScrollTo, { offset: -50 })

	// Register other global properties
	app.config.globalProperties.$store = store
	app.config.globalProperties.$http = axios.create() // This is needed for vue-authenticate-2

	// Register global components
	app.component('loader', Loader)
	app.component('checkbox', Checkbox)
	app.component('radio', Radio)

	// Setup modal
	app.component('vue-modal', Modal)
	app.component('responsive-image', ResponsiveImage)

	// Adding height transitions
	app.component("transition-height", transitionHeight)

	// New font-awesome inclusion (version 5+)
	app.component('font-awesome-icon', FontAwesomeIcon)

	// Mount the app and make it a global shortcut
	window.$appInstance = app
	window.$app = app.mount("#challengerApp")
})
.catch(e => {
	store.commit('setOffline', true)
})

// Set Vite to reload if preload fails
// https://vitejs.dev/guide/build#load-error-handling
window.addEventListener('vite:preloadError', () => {
  window.location.reload() // for example, refresh the page
})